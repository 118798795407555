import Charger from "../classes/charger_classes/charger";
import Trip from "../classes/trip_classes/trip";
import { PowerType } from "../types/charger_Db_types";

const acPowerTypes: PowerType[] = [
  "AC_1_PHASE",
  "AC_2_PHASE",
  "AC_2_PHASE_SPLIT",
  "AC_3_PHASE",
];

export interface TripAcDcSplit {
  acChargingTime: number;
  dcChargingTime: number;
}

/**
 * Calculate the total AC and DC charging times for a given trip.
 *
 * @param {Trip} trip - The trip to calculate the charging times for.
 * @param {Charger[]} chargers - The list of chargers to check against.
 * @returns {TripAcDcSplit} An object with the total AC and DC charging times.
 */
export default function calcTripAcDcSplit(trip: Trip, chargers: Charger[]) {
  let acChargingTime = 0;
  let dcChargingTime = 0;

  if (!trip.vehicle_data) return { acChargingTime, dcChargingTime };

  for (let i = 0; i < trip.itinerary.steps.length; i++) {
    const step = trip.itinerary.steps[i];
    const charger = chargers.find(
      (charger) => charger.id === step.locationCDBID
    );
    if (!charger) continue;
    const bestCompatibleConnector = charger.bestCompatibleConnector(
      trip.vehicle_data
    );
    if (!bestCompatibleConnector) continue;
    const isAC = acPowerTypes.includes(bestCompatibleConnector.powerType);

    if (isAC) {
      acChargingTime += step.chargingTime;
    } else {
      dcChargingTime += step.chargingTime;
    }
  }

  return { acChargingTime, dcChargingTime };
}
