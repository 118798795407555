<template>
  <v-card flat class="background">
    <v-card-text>{{ modelName || "Unknown" }}</v-card-text>
    <template v-if="savedStyle">
      <v-card-text>
        <v-row no-gutters>
          <v-col
            v-for="(trip, i) in groupedTrips.trips"
            :key="'saved-trip-' + i"
            cols="12"
            md="6"
          >
            <div
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? i % 2 === 0
                    ? 'pr-1'
                    : 'pl-1'
                  : ''
              "
            >
              <SavedTripCard
                :trip="trip"
                :style="$vuetify.breakpoint.mdAndUp ? 'height: 100%' : ''"
                emitOnly
                @view-trip="showTripDetails(trip)"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template v-else>
      <UnsavedTripCard
        v-for="(trip, index) in groupedTrips.trips"
        :key="`unsaved-trip-card-${index}`"
        :trip="trip"
      />
    </template>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { State } from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip_classes/trip";
import SavedTripCard from "./SavedTripCard.vue";
import UnsavedTripCard from "./UnsavedTripCard.vue";

interface SortedTripsGroup {
  evModel: string;
  trips: Trip[];
}

export default Vue.extend({
  name: "StoredTripsGroupCard",
  props: {
    groupedTrips: Object as PropType<SortedTripsGroup>,
    savedStyle: { default: false, type: Boolean },
  },
  computed: {
    modelName(): string | undefined {
      return (this.$store.state as State).evModels?.find(
        (model) => model.id === this.groupedTrips.evModel
      )?.name;
    },
  },
  components: { UnsavedTripCard, SavedTripCard },
  methods: {
    showTripDetails(trip: Trip) {
      this.$emit("view-trip", trip);
    },
  },
});
</script>
