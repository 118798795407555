<template>
  <!-- planning animation page -->
  <InfoPanelCardWrapper v-if="planning || routePlanningStatus === 'PROCESSING'">
    <DescriptiveLoader message="Power Trip is planning your trip" />
  </InfoPanelCardWrapper>
  <!-- trip failed status alert -->
  <InfoPanelCardWrapper v-else-if="trip && trip.status === 'failed'">
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-md-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            style="max-width: 15%"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card flat class="mx-4 my-10" color="#FFD1D9" style="border-radius: 8px">
      <v-card-title>
        <v-icon color="#C8102E" class="mr-2">
          mdi-alert-octagon-outline
        </v-icon>
        <span class="text-body-1 font-weight-regular">
          Currently unable to plan your trip.
        </span>
      </v-card-title>
    </v-card>
    <v-card flat class="text-center">
      <v-card-title>
        <v-spacer></v-spacer>
        Please try again...
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mb-10">
        We don’t have enough information to plan your trip. Please try
        replanning or if errors persists, email us at
        <a href="mailto:support@powertrip.earth" target="_blank">
          support@powertrip.earth </a
        >.
      </v-card-text>
      <v-card-actions class="justify-center">
        <ElevatedBtn @click="home"> Plan another trip </ElevatedBtn>
      </v-card-actions>
    </v-card>
  </InfoPanelCardWrapper>
  <!-- Itinerary page -->
  <InfoPanelCardWrapper v-else-if="trip">
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-md-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            style="max-width: 15%"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <!-- trip in logistics mode status alert -->
    <v-card
      v-if="trip.status === 'fallback'"
      color="#FFEDC4"
      flat
      style="border-radius: 8px"
      class="mx-4 mt-10 mb-5"
      @click="showLogisticsModeInfo = true"
      :disabled="!logisticsModeTooltip"
    >
      <v-card-title>
        <v-icon color="#F89D1F" class="mr-1">
          mdi-map-marker-alert-outline
        </v-icon>
        <span class="text-body-1 font-weight-regular">
          This trip was planned in logistics mode.
        </span>
      </v-card-title>
      <GenericDialog
        v-if="logisticsModeTooltip"
        :activator="showLogisticsModeInfo"
        @close="showLogisticsModeInfo = false"
      >
        <v-card flat color="transparent">
          <v-card-title> {{ logisticsModeTooltip.Name }} </v-card-title>
          <v-card-text v-html="logisticsModeTooltip.Content"></v-card-text>
        </v-card>
      </GenericDialog>
    </v-card>
    <!-- trip details section -->
    <TripDetailsSection :trip="trip" />
    <!-- button band -->
    <v-row class="px-4 mb-5" no-gutters>
      <CardBtn
        label="Info"
        class="ml-1"
        color="transparent"
        icon-color="primary"
        icon="mdi-poll"
        @click="toStats"
      />
      <CardBtn
        label="Save"
        class="ml-1"
        color="transparent"
        icon-color="primary"
        icon="mdi-marker-check"
        @click="showSave = true"
      />
      <CardBtn
        label="Share"
        class="ml-1"
        color="transparent"
        icon-color="primary"
        icon="mdi-export-variant"
      />
      <v-spacer />
      <CardBtn
        label="Book"
        class="mr-1"
        color="transparent"
        icon-color="primary"
        icon="mdi-car-select"
        @click="showBooking = true"
        :disabled="!trip.vehicle_data || !trip.vehicle_data.directusId"
      />
    </v-row>
    <!-- Itinerary section -->
    <v-card-text>
      <ItineraryMain
        :itinerary="trip.itinerary"
        @removeStep="removeChargingStop"
      />
    </v-card-text>
    <!-- save dialog -->
    <GenericDialog :activator="showSave" @close="showSave = false" small>
      <SaveRoutePlanContent :routePlan="trip" @close="showSave = false" />
    </GenericDialog>
    <!-- booking dialog -->
    <GenericDialog
      v-if="trip.vehicle_data && trip.vehicle_data.directusId"
      :activator="showBooking"
      @close="showBooking = false"
    >
      <VehicleBookingContent :vehicle="trip.vehicle_data" />
    </GenericDialog>
  </InfoPanelCardWrapper>
  <!-- No Trip Page -->
  <InfoPanelCardWrapper v-else>
    <NoTripContent />
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import InfoPanelCardWrapper from "../components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { mapGetters, mapState } from "vuex";
import {
  ActionTypes,
  AsyncStatus,
  GettersTypes,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import Trip from "@/logic/classes/trip_classes/trip";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import queryValueToString from "@/logic/utils/queryValueToString";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import ItineraryMain from "@/ui/components/trips/Itinerary/ItineraryMain.vue";
import NoTripContent from "../components/trips/NoTripContent.vue";
import { RawLocation } from "vue-router";
import CardBtn from "../components/ui-elements/buttons/CardBtn.vue";
import TripsHomeBtn from "@/ui/components/ui-elements/buttons/TripsHomeBtn.vue";
import TripDetailsSection from "../components/trips/planning/TripDetailsSection.vue";
import GenericDialog from "../components/dialog-wrappers/GenericDialog.vue";
import SaveRoutePlanContent from "../components/trips/SaveRoutePlanContent.vue";
import DescriptiveLoader from "../components/ui-elements/loaders/DescriptiveLoader.vue";
import VehicleBookingContent from "../components/booking/VehicleBookingContent.vue";
import ElevatedBtn from "../components/ui-elements/buttons/ElevatedBtn.vue";

export default Vue.extend({
  name: "PlanningItineraryView",
  components: {
    InfoPanelCardWrapper,
    ItineraryMain,
    NoTripContent,
    TripsHomeBtn,
    CardBtn,
    TripDetailsSection,
    GenericDialog,
    SaveRoutePlanContent,
    DescriptiveLoader,
    VehicleBookingContent,
    ElevatedBtn,
  },
  data() {
    return {
      planning: true,
      showSave: false,
      showBooking: false,
      showLogisticsModeInfo: false,
      // breadcrumb section
      items: [
        {
          text: "Add Destination",
          to: { name: RouteNames.tripAddDestination },
        },
        {
          text: "Add Origin",
          to: { name: RouteNames.tripAddOrigin },
        },
        {
          text: "Add Details",
          to: { name: RouteNames.tripAddDetails },
        },
        {
          text: "Add Stops",
          to: { name: RouteNames.tripAddStops },
        },
        {
          text: "Select Vehicle",
          to: { name: RouteNames.tripSelectVehicle },
        },
        {
          text: "Itinerary",
          to: { name: RouteNames.tripItinerary },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
      fetching: GettersTypes.fetching,
    }),
    ...mapState({
      routePlanningStatus: (state: unknown): AsyncStatus | undefined =>
        (state as State).routePlanningStatus,
      logisticsModeTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) => content.Description === "logistics mode tooltip"
        ),
    }),
  },
  methods: {
    // Routing
    home() {
      this.$store.commit(MutationTypes.setSelectedTrip, undefined);
      this.pushRoute(RouteNames.trips);
    },
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toStats() {
      this.$router.push({
        name: RouteNames.tripStats,
        query: this.$route.query,
      });
    },
    async buildTripFromQuery() {
      this.planning = true; // trigger planning animation

      // get query values
      const originAddress = queryValueToString(this.$route.query.origAddress);
      const originLat = queryValueToNumber(this.$route.query.origLat);
      const originLon = queryValueToNumber(this.$route.query.origLon);
      const originName = queryValueToString(this.$route.query.origName);

      const destAddress = queryValueToString(this.$route.query.destAddress);
      const destLat = queryValueToNumber(this.$route.query.destLat);
      const destLon = queryValueToNumber(this.$route.query.destLon);
      const destName = queryValueToString(this.$route.query.destName);

      let waypoints: TripLocation[] = [];
      const stopKeys = Object.keys(this.$route.query).filter((key) =>
        key.startsWith("stop")
      );
      let groupedKeys = [];
      for (let i = 0; i < stopKeys.length; i++) {
        const key = stopKeys[i];
        const stopNumber = parseIntOrFloat(key[4]); // ASSUMES pattern of `stop[stop index + 1][stop property key]` is still being used
        if (!stopNumber) continue;
        if (!groupedKeys[stopNumber - 1]) groupedKeys[stopNumber - 1] = [key];
        else groupedKeys[stopNumber - 1].push(key);
      }
      for (let index = 0; index < groupedKeys.length; index++) {
        const group = groupedKeys[index];
        const newWaypoint = new TripLocation();
        for (let i = 0; i < group.length; i++) {
          const key = group[i];
          if (key === `stop${index + 1}Address`) {
            const parsedAddress = queryValueToString(this.$route.query[key]);
            if (parsedAddress) newWaypoint.address = parsedAddress;
          }
          if (key === `stop${index + 1}Lat`) {
            const parsedLat = queryValueToNumber(this.$route.query[key]);
            if (parsedLat) newWaypoint.coordinates.latitude = parsedLat;
          }
          if (key === `stop${index + 1}Lon`) {
            const parsedLon = queryValueToNumber(this.$route.query[key]);
            if (parsedLon) newWaypoint.coordinates.longitude = parsedLon;
          }
          if (key === `stop${index + 1}Name`) {
            const parsedName = queryValueToString(this.$route.query[key]);
            if (parsedName) newWaypoint.name = parsedName;
          }
          if (key === `stop${index + 1}Stay`) {
            const parsedStay = queryValueToNumber(this.$route.query[key]);
            if (parsedStay) newWaypoint.stay = parsedStay;
          }
          if (key === `stop${index + 1}LeavingSoC`) {
            const parsedSoC = queryValueToNumber(this.$route.query[key]);
            if (parsedSoC) newWaypoint.stateOfChargeAfterCharging = parsedSoC;
          }
          if (key === `stop${index + 1}Weight`) {
            const parsedWeight = queryValueToNumber(this.$route.query[key]);
            if (parsedWeight) newWaypoint.weightChange = parsedWeight;
          }
          if (key === `stop${index + 1}UsedEnergy`) {
            const parsedEnergy = queryValueToNumber(this.$route.query[key]);
            if (parsedEnergy) newWaypoint.nonDrivingEnergyUsed = parsedEnergy;
          }
        }
        waypoints.push(newWaypoint);
      }
      // guard clauses
      if (!originAddress || !originLat || !originLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      if (!destAddress || !destLat || !destLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      // prep locations
      const locations: TripLocation[] = [];
      // add origin
      locations.push(
        new TripLocation({
          address: originAddress,
          coordinates: new Coordinate({
            latitude: originLat,
            longitude: originLon,
          }),
          name: originName,
        })
      );
      // add stops

      locations.push(...waypoints);
      // add destination
      locations.push(
        new TripLocation({
          address: destAddress,
          coordinates: new Coordinate({
            latitude: destLat,
            longitude: destLon,
          }),
          name: destName,
        })
      );
      // create trip
      const trip = new Trip({
        locations: locations,
        roundTripFlag: false,
      });
      trip.SOCAct =
        queryValueToNumber(this.$route.query.SOCAct) ??
        (this.$store.state as State).SOCAct;
      trip.SOCEnd =
        queryValueToNumber(this.$route.query.SOCEnd) ??
        (this.$store.state as State).SOCEnd;
      trip.SOCMin =
        queryValueToNumber(this.$route.query.SOCMin) ??
        (this.$store.state as State).SOCMin;
      trip.SOCMax =
        queryValueToNumber(this.$route.query.SOCMax) ??
        (this.$store.state as State).SOCMax;
      trip.SpeedAdjustment = queryValueToNumber(this.$route.query.speedAdj);
      trip.startingLoad =
        queryValueToNumber(this.$route.query.extraWeight) ?? 0;
      trip.passengers = queryValueToNumber(this.$route.query.passengers) ?? 0;
      trip.vehicle = this.$store.getters[GettersTypes.selectedVehicleData] as
        | Vehicle
        | undefined;
      // plan trip
      await trip.planTrip();
      this.$store.dispatch(ActionTypes.showTrip, trip);
      // this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      // this.$store.commit(MutationTypes.setSelectedTrip, trip.local_id);
      this.planning = false;
    },
    updateRouteQuery() {
      const typedTrip = this.trip as Trip | undefined;
      if (!typedTrip) return;

      const newQuery = {
        ...this.$route.query,
      };

      const originAddressData = typedTrip.locations[0];
      const destinationAddressData = typedTrip.roundTripFlag
        ? typedTrip.locations[0]
        : typedTrip.locations[typedTrip.locations.length - 1];
      const waypoints = [...typedTrip.locations];
      waypoints.shift(); // remove origin
      if (!typedTrip.roundTripFlag) waypoints.pop(); // remove destination

      if (destinationAddressData) {
        newQuery.destAddress = encodeURI(destinationAddressData.address);
        newQuery.destLat =
          destinationAddressData.coordinates.latitude.toString();
        newQuery.destLon =
          destinationAddressData.coordinates.longitude.toString();
        if (destinationAddressData.name) {
          newQuery.destName = destinationAddressData.name;
        } else {
          delete newQuery.destName;
        }
      } else {
        delete newQuery.destAddress;
        delete newQuery.destLat;
        delete newQuery.destLon;
        delete newQuery.destName;
      }

      if (originAddressData) {
        newQuery.origAddress = encodeURI(originAddressData.address);
        newQuery.origLat = originAddressData.coordinates.latitude.toString();
        newQuery.origLon = originAddressData.coordinates.longitude.toString();
        if (originAddressData.name) {
          newQuery.origName = originAddressData.name;
        } else {
          delete newQuery.origName;
        }
      } else {
        delete newQuery.origAddress;
        delete newQuery.origLat;
        delete newQuery.origLon;
        delete newQuery.origName;
      }

      // rebuild stops

      Object.keys(newQuery)
        .filter((key) => key.startsWith("stop"))
        .forEach((key) => {
          delete newQuery[key];
        }); // remove old stops

      waypoints.forEach((stop, index) => {
        newQuery[`stop${index + 1}Address`] = encodeURI(stop.address);
        newQuery[`stop${index + 1}Lat`] = stop.coordinates.latitude.toString();
        newQuery[`stop${index + 1}Lon`] = stop.coordinates.longitude.toString();
        if (stop.name) newQuery[`stop${index + 1}Name`] = stop.name;
        if (stop.stay) newQuery[`stop${index + 1}Stay`] = stop.stay.toString();
        if (stop.stateOfChargeAfterCharging)
          newQuery[`stop${index + 1}LeavingSoC`] =
            stop.stateOfChargeAfterCharging.toString();
        if (stop.weightChange)
          newQuery[`stop${index + 1}Weight`] = stop.weightChange.toString();
        if (stop.nonDrivingEnergyUsed)
          newQuery[`stop${index + 1}UsedEnergy`] =
            stop.nonDrivingEnergyUsed.toString();
      }); // add new stops

      // check if nothing changed
      if (
        Object.keys(newQuery).every(
          (key) => newQuery[key] === this.$route.query[key]
        ) &&
        Object.keys(this.$route.query).every(
          (key) => newQuery[key] === this.$route.query[key]
        )
      )
        return; // nothing to update

      // update query
      this.$router.replace({
        query: newQuery,
      });
    },
    async removeChargingStop(CDBID: string) {
      this.planning = true;
      await this.trip.removeChargingStop(CDBID);
      this.$store.commit(MutationTypes.updateIndividualTrip, this.trip);
      this.planning = false;
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      // ASSUMES: if still fetching base optimiser data watch will be triggered once finished.
      if (!this.fetching) {
        if (this.trip) {
          if (this.trip.displayedComparisonData) {
            this.trip.displayedComparisonId = undefined;
            this.$store.commit(MutationTypes.updateIndividualTrip, this.trip);
          }
          await this.updateRouteQuery();
          this.planning = false;
        }
        if (!this.trip) await this.buildTripFromQuery();
      }
    });
  },
  watch: {
    async fetching(val) {
      if (!val) {
        if (this.trip) this.planning = false;
        if (!this.trip) await this.buildTripFromQuery();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripAddStops ||
        to.name === RouteNames.tripAddDetails ||
        to.name === RouteNames.tripAddOrigin ||
        to.name === RouteNames.tripAddDestination ||
        to.name === RouteNames.tripSelectVehicle ||
        to.name === RouteNames.tripStats) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
});
</script>
