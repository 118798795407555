<template>
  <v-card
    class="mb-3"
    @click="showTrip"
    :loading="fetching"
    :disabled="fetching"
  >
    <v-row no-gutters align="center">
      <v-card-title
        v-if="trip.name"
        class="text-truncate"
        style="word-break: normal"
      >
        <span class="text-truncate">{{ trip.name }}</span>
      </v-card-title>
      <v-card-title v-else class="text-truncate" style="word-break: normal">
        <span class="text-truncate">
          Trip to
          {{
            trip.locations[trip.locations.length - 1].name ??
            trip.locations[trip.locations.length - 1].address
          }}
        </span>
      </v-card-title>
    </v-row>
    <v-card-text>
      <ul style="list-style: none; padding-left: unset">
        <li>{{ niceDuration(trip.itinerary.totalTravelTime) }}</li>
        <li>{{ trip.chargingStopCDBIDs.length }} charging stops</li>
      </ul>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn icon @click.stop="saveTrip">
        <v-progress-circular
          indeterminate
          color="primary"
          size="30"
          v-if="processing && savingStatus === 'PROCESSING'"
        ></v-progress-circular>
        <v-icon
          color="success"
          large
          v-else-if="processing && savingStatus === 'SUCCESS'"
        >
          mdi-check</v-icon
        >
        <v-icon
          color="error"
          large
          v-else-if="processing && savingStatus === 'FAILED'"
        >
          mdi-close</v-icon
        >
        <v-icon color="primary" large v-else> mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="showDialog" width="300">
      <v-card class="rounded-lg">
        <v-card-title> Name it and save it </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="inputtedName"
            label="name"
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <ElevatedBtn @click="nameAndSave"> Save </ElevatedBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import Vue, { PropType } from "vue";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import { ActionTypes, GettersTypes, State } from "@/logic/store/store_types";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
  name: "UnsavedTripCard",
  props: { trip: Object as PropType<Trip> },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    showTrip() {
      this.$store.dispatch(ActionTypes.showTrip, this.trip);
    },
    saveTrip() {
      this.processing = true;
      // check if trip has name
      if (!this.trip.name) {
        this.showDialog = true;
        return;
      }
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Trip Saved",
          count: 1,
        },
      ]);
      this.$store.dispatch(ActionTypes.saveTrip, this.trip.local_id);
    },
    nameAndSave() {
      this.processing = true;
      if (this.inputtedName) {
        this.$store.dispatch(ActionTypes.nameAndSaveTrip, {
          tripID: this.trip.local_id,
          name: this.inputtedName,
        });
      } else {
        this.$store.dispatch(ActionTypes.saveTrip, this.trip.local_id);
      }
      this.showDialog = false;
    },
  },
  data() {
    return {
      showDialog: false,
      inputtedName: "",
      processing: false,
    };
  },
  computed: {
    ...mapState({
      savingStatus: (state: unknown) => (state as State).tripSavingStatus,
    }),
    ...mapGetters({
      fetching: GettersTypes.fetching,
    }),
  },
  watch: {
    savingStatus() {
      if (!this.savingStatus) {
        this.processing = false;
      }
    },
  },
});
</script>
