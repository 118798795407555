var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{staticStyle:{"z-index":"1200"},attrs:{"value":!!_vm.mainDialogContent,"width":_vm.$vuetify.breakpoint.mdAndUp ? '70%' : '80%',"content-class":_vm.$vuetify.breakpoint.xs ? '' : 'rounded-lg',"scrollable":"","max-width":_vm.$vuetify.breakpoint.mdAndUp ? '800px' : '650px',"min-width":_vm.$vuetify.breakpoint.mdAndUp ? '600px' : '350px',"fullscreen":_vm.$vuetify.breakpoint.xs,"retain-focus":""},on:{"click:outside":function($event){return _vm.clear()},"keydown":function($event){return _vm.keyDown($event)}}},[_c('v-sheet',{staticClass:"overflow-hidden pb-5",style:(_vm.$vuetify.breakpoint.xs ? 'min-height: 100vh;' : 'min-height: 85vh;')},[(
        // mainDialogContent !== 'charging station details' &&
        _vm.mainDialogContent !== 'fleetVehicleDetails' &&
        _vm.mainDialogContent !== 'vehicle settings' &&
        _vm.mainDialogContent !== 'vehicle booking' &&
        _vm.mainDialogContent !== 'vehicle gallery custom' &&
        _vm.mainDialogContent !== 'vehicle gallery connected' &&
        _vm.mainDialogContent !== 'vehicle gallery generic'
      )?_c('v-toolbar',{attrs:{"flat":"","extension-height":"24"}},[(!!_vm.icon)?_c('v-icon',{staticClass:"mx-3",attrs:{"color":"primary","large":""}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(_vm.title)?_c('h2',{staticClass:"primary--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"align-self-start",attrs:{"icon":""},on:{"click":function($event){return _vm.clear()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('div',{staticClass:"pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden px-2 px-sm-0",style:(_vm.mainDialogContent !== 'charging station details' &&
        _vm.mainDialogContent !== 'fleetVehicleDetails' &&
        _vm.mainDialogContent !== 'vehicle settings' &&
        _vm.mainDialogContent !== 'vehicle booking' &&
        _vm.mainDialogContent !== 'vehicle gallery custom' &&
        _vm.mainDialogContent !== 'vehicle gallery connected' &&
        _vm.mainDialogContent !== 'vehicle gallery generic'
          ? 'height: calc(100% - 64px)'
          : 'height: 100%')},[(_vm.mainDialogContent === 'charging station details')?_c('ChargingStationDetailsContent'):_vm._e(),(_vm.mainDialogContent === 'vehicle gallery')?_c('AllVehiclesGalleryContent'):_vm._e(),(_vm.mainDialogContent === 'vehicle gallery custom')?_c('VehicleGalleryContent',{attrs:{"type":"CUSTOM_VEHICLES"}}):_vm._e(),(_vm.mainDialogContent === 'vehicle gallery connected')?_c('VehicleGalleryContent',{attrs:{"type":"CONNECTED_VEHICLES"}}):_vm._e(),(_vm.mainDialogContent === 'vehicle gallery generic')?_c('VehicleGalleryContent',{attrs:{"type":"GENERIC_VEHICLES"}}):_vm._e(),(_vm.mainDialogContent === 'fleetVehicleDetails')?_c('FleetVehicleDetailsContent'):_vm._e(),(_vm.mainDialogContent === 'add vehicle')?_c('AddVehicleContent'):_vm._e(),(_vm.mainDialogContent === 'vehicle settings')?_c('VehicleSettingsContent'):_vm._e(),(_vm.mainDialogContent === 'vehicle booking')?_c('VehicleBookingContent'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }