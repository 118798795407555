<template>
  <v-card class="rounded-lg mb-5 px-4" elevation="2">
    <v-card-title>
      Charging Range
      <v-spacer></v-spacer>
      <StyledToolTip v-if="chargingRangeTooltip" :data="chargingRangeTooltip" />
    </v-card-title>
    <v-card-text class="mb-5">
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Minimum Charge"
            :value="displayRange[0]"
            suffix="%"
            type="number"
            hide-spin-buttons
            :rules="[validateRange, validateMinRange]"
            @input="handleRangeMinSet"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Maximum Charge"
            :value="displayRange[1]"
            suffix="%"
            type="number"
            hide-spin-buttons
            :rules="[validateRange, validateMaxRange]"
            @input="handleRangeMaxSet"
          />
        </v-col>
      </v-row>
      <v-range-slider
        @end="handleRangeSet"
        v-model="displayRange"
        color="primary"
        track-color="grey lighten-2"
        class="pt-0"
        min="1"
        max="100"
        hide-details
      ></v-range-slider>
    </v-card-text>
    <v-card-title>
      Extra Weight (kg)
      <v-spacer></v-spacer>
      <StyledToolTip v-if="extraWeightTooltip" :data="extraWeightTooltip" />
    </v-card-title>
    <v-card-text>
      <BatteryLikeInput
        :wholeNumber="true"
        :passedValue="extraWeight"
        @update="handleWeightSet"
      />
    </v-card-text>
    <v-card-title>
      Passengers
      <v-spacer></v-spacer>
      <StyledToolTip v-if="passengersTooltip" :data="passengersTooltip" />
    </v-card-title>
    <v-card-text>
      <BatteryLikeInput
        :wholeNumber="true"
        :passedValue="passengers"
        @update="handlePassengersSet"
      />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import BatteryLikeInput from "@/ui/components/ui-elements/inputs/BatteryLikeInput.vue";
import { State } from "@/logic/store/store_types";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import StyledToolTip from "../../ui-elements/StyledToolTip.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "ExtraDetailsCard",
  components: {
    BatteryLikeInput,
    StyledToolTip,
  },
  data() {
    return {
      extraWeight: 0,
      passengers: 0,
      displayRange: [10, 90],
    };
  },
  computed: {
    ...mapState({
      chargingRangeTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) =>
            content.Description === "trip details charging range tooltip"
        ),
      extraWeightTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) =>
            content.Description === "trip details extra weight tooltip"
        ),
      passengersTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) => content.Description === "trip details passengers tooltip"
        ),
    }),
  },
  methods: {
    handleWeightSet(value: number) {
      this.extraWeight = value;
      this.$emit("updateWeight", value);
    },
    handlePassengersSet(value: number) {
      this.passengers = value;
      this.$emit("updatePassengers", value);
    },
    handleRangeSet() {
      this.$emit("updateRange", {
        min:
          this.displayRange[0] ===
          Math.round((this.$store.state as State).SOCMin * 100)
            ? undefined
            : this.displayRange[0] / 100,
        max:
          this.displayRange[1] ===
          Math.round((this.$store.state as State).SOCMin * 100)
            ? undefined
            : this.displayRange[1] / 100,
      });
    },
    handleRangeMinSet(value: number | string): void {
      if (this.validateRange(value) === true) {
        const parsedVal = parseIntOrFloat(value);
        if (!parsedVal) return;
        if (parsedVal > this.displayRange[1]) return; // prevent out of order
        this.displayRange = [parsedVal, this.displayRange[1]];
        this.handleRangeSet();
      }
    },
    handleRangeMaxSet(value: number | string): void {
      if (this.validateRange(value) === true) {
        const parsedVal = parseIntOrFloat(value);
        if (!parsedVal) return;
        if (parsedVal < this.displayRange[0]) return; // prevent out of order
        this.displayRange = [this.displayRange[0], parsedVal];
        this.handleRangeSet();
      }
    },
    validateRange(val: number | string): boolean | string {
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      if (parsedVal < 1 || parsedVal > 100) return "needs to be 1-100";
      return true;
    },
    validateMinRange(val: number | string): boolean | string {
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      if (parsedVal > this.displayRange[1]) return "needs to be less than max";
      return true;
    },
    validateMaxRange(val: number | string): boolean | string {
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      if (parsedVal < this.displayRange[0]) return "needs to be more than min";
      return true;
    },
  },
  mounted() {
    // set default values
    const parsedSOCMin = queryValueToNumber(this.$route.query.SOCMin);
    const parsedSOCMax = queryValueToNumber(this.$route.query.SOCMax);
    const parsedPassengers = queryValueToNumber(this.$route.query.passengers);
    const parsedWeight = queryValueToNumber(this.$route.query.extraWeight);
    this.displayRange = [
      Math.round(
        (parsedSOCMin ? parsedSOCMin : (this.$store.state as State).SOCMin) *
          100
      ),
      Math.round(
        (parsedSOCMax ? parsedSOCMax : (this.$store.state as State).SOCMax) *
          100
      ),
    ];
    if (parsedPassengers) this.passengers = parsedPassengers;
    if (parsedWeight) this.extraWeight = parsedWeight;
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
