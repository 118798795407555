<template>
  <div class="pwt-custom-overlay">
    <v-card
      class="px-4 pb-3 rounded-0 d-flex flex-column align-items-start justify-start"
      light
      :max-width="$vuetify.breakpoint.xsOnly ? '' : '800px'"
      :min-width="$vuetify.breakpoint.xsOnly ? '' : '350px'"
      :min-height="$vuetify.breakpoint.xsOnly ? '' : '100px'"
      :width="$vuetify.breakpoint.xsOnly ? '100vw' : '40%'"
      height="100vh"
      @keydown="keyDown($event)"
    >
      <template v-if="checkingToken">
        <v-card-text
          v-if="$vuetify.breakpoint.xsOnly"
          class="d-flex flex-column align-items-center justify-center"
        >
          <span style="width: 100%" class="text-h5 text-center">
            Cheeking authentication
          </span>
          <div style="width: 100%" class="d-flex justify-center">
            <LoadingCard />
          </div>
        </v-card-text>
        <v-card-text
          style="height: 100%; min-height: 100px; position: relative"
          class="d-flex justify-center align-center px-8 pt-5"
          v-else
        >
          <v-progress-circular indeterminate color="primary" class="mr-2" />
          <span class="text-h5">Checking authentication</span>
          <PulseLoader />
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title
          class="text-h3"
          style="font-family: Public Sans !important"
        >
          Optimiser
        </v-card-title>
        <v-card-text class="text-body-1 font-weight-medium" style="">
          Power Trip's complete EV management suite
        </v-card-text>
        <v-card
          v-if="status === 'FAILED' || status === undefined"
          flat
          class="px-4 pt-10"
        >
          <v-btn
            :href="redirectUrl"
            color="primary"
            class="text-none rounded-pill"
            outlined
            block
          >
            Login with {{ ssoName }}
          </v-btn>
        </v-card>
        <v-card-text class="d-flex red--text" v-if="status === 'FAILED'"
          ><v-icon color="red" class="mr-1">mdi-alert-circle</v-icon> Whoops!
          Looks like your login details are not correct. Please check your
          details and try again.</v-card-text
        >
        <v-card-text class="d-flex" v-if="status === 'SUCCESS'">
          <v-icon color="green" class="mr-1">mdi-check-circle</v-icon>
          Successfully authenticated.
        </v-card-text>
        <template v-if="status === 'PROCESSING'">
          <v-card-text
            v-if="$vuetify.breakpoint.xsOnly"
            class="d-flex align-items-center justify-center"
          >
            <LoadingCard />
          </v-card-text>
          <v-card-text v-else class="d-flex align-items-center">
            <v-progress-circular
              indeterminate
              color="primary"
              class="mr-1"
              size="22"
            />
            <span class="d-flex align-center">
              <span> Processing </span>
              <PulseLoader />
            </span>
          </v-card-text>
        </template>
      </template>
    </v-card>
  </div>
</template>
<script lang="ts">
import PulseLoader from "../../ui-elements/loaders/PulseLoader.vue";
import Vue from "vue";
import { mapState } from "vuex";
import LoadingCard from "../../ui-elements/loaders/LoadingCard.vue";
import { State } from "@/logic/store/store_types";
import {
  directusBaseURL,
  passedSSOName,
} from "@/logic/api/clients/directusClient";
import { capitalizeFirstLetter } from "@/logic/utils/stringUtils";

export default Vue.extend({
  name: "LoginOverlay",
  computed: {
    ...mapState({
      display: (state: unknown) => (state as State).showLogin,
      status: (state: unknown) => (state as State).authStatus,
      checkingToken: (state: unknown) => (state as State).checkingToken,
    }),
    redirectUrl() {
      return `https://${directusBaseURL}/auth/login/sso?prompt=login&redirect=${window.location.origin + window.location.pathname}`;
    },
  },
  data() {
    return {
      ssoName: passedSSOName ? capitalizeFirstLetter(passedSSOName) : "SSO",
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Enter") {
        window.location.href = this.redirectUrl;
      }
    },
  },
  components: {
    PulseLoader,
    LoadingCard,
  },
});
</script>
<style scoped>
.pwt-custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1400;
  display: flex;
  backdrop-filter: blur(2px);
}
</style>
