var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('DashboardBreadcrumbs',{attrs:{"items":_vm.showingTrip
        ? [
            { text: 'Home' },
            { text: 'Saved trips' },
            { text: 'Trip details', disabled: true },
          ]
        : [{ text: 'Home' }, { text: 'Saved trips', disabled: true }]},on:{"navigation":_vm.breadcrumbNavigation,"back":function($event){return _vm.breadcrumbNavigation({ text: 'Home' })}}}):_vm._e(),(_vm.showingTrip)?_c('TripDetailsPage',{attrs:{"trip":_vm.showingTrip},on:{"back":function($event){_vm.showingTrip = undefined},"view-trip":_vm.close,"view-stats":_vm.close}}):_c('v-card',{staticClass:"p7-5 pa-md-5 background",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Unsaved trips ")]),_c('v-card-text',[_c('UnsavedTripCarousel')],1),_c('v-card-title',[_vm._v(" Saved trips ")]),_c('FrequentTripSavings'),_vm._l((_vm.savedTripsGroupedByEV),function(group,groupIndex){return _c('StoredTripsGroupCard',{key:`saved-trip-group-${groupIndex}`,attrs:{"groupedTrips":group,"savedStyle":""},on:{"view-trip":_vm.showTripDetails}})}),(!_vm.savedTripsGroupedByEV.length)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-0"},[_vm._v(" You currently have no saved trips. ")])],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }