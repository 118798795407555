import {
  QuickTripCheckReturn,
  Valhalla_CostingModel,
  Valhalla_Location,
  Valhalla_RouteRes,
  Valhalla_RouteError,
} from "@/logic/types/valhalla_types";
import { valhallaBaseUrl } from "../clients/valhallaClient";

export async function quickTripCheck(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = "auto"
): Promise<QuickTripCheckReturn> {
  try {
    const json = {
      locations: locations,
      costing: costing,
    };

    const res = await fetch(
      `${valhallaBaseUrl}route?json=${JSON.stringify(json)}`
    );
    const convertedResponse = await res.json();
    if (Object.hasOwn(convertedResponse, "trip")) {
      return QuickTripCheckReturn.routable;
    } else if (
      Object.hasOwn(convertedResponse, "error_code") &&
      convertedResponse.error_code === 170
    ) {
      return QuickTripCheckReturn.unconnected_regions;
    } else {
      return QuickTripCheckReturn.not_routable;
    }
  } catch (error) {
    // handled in previous code block.
    return QuickTripCheckReturn.not_routable;
  }
}

/**
 * Fetches an optimized route plan from the Valhalla routing service.
 *
 * Sends a request to the Valhalla routing API with the provided locations and costing model.
 * If the response is successful (200), a `Valhalla_RouteRes` object is returned.
 * If the response is a 400 error, a `Valhalla_RouteError` object is returned.
 * If any error occurs, the function returns `undefined`.
 * @param locations The list of locations to fetch the route plan for.
 * @param costing The costing model to use. Defaults to "auto".
 * @returns A promise that resolves with the route plan, or undefined if an error occurs.
 */
export async function fetchValhallaOptimizedRoutePlan(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = "auto"
): Promise<Valhalla_RouteRes | Valhalla_RouteError | undefined> {
  try {
    const json = {
      locations: locations,
      costing: costing,
    };

    const res = await fetch(
      `${valhallaBaseUrl}optimized_route?json=${JSON.stringify(json)}`
    );

    if (res.status === 200) {
      const convertedResponse: Valhalla_RouteRes = await res.json();
      return convertedResponse;
    }

    if (res.status === 400) {
      const convertedResponse: Valhalla_RouteError = await res.json();
      return convertedResponse;
    }
  } catch (error) {
    console.log("fetchValhallaOptimizedRoutePlan errored with", error);
  }
}

/**
 * Fetches a route plan from the Valhalla routing service.
 *
 * Sends a request to the Valhalla routing API with the provided locations and costing model.
 * Returns a Valhalla route response if successful or an error response if there is a problem
 * with the request. If an error occurs during the fetch operation, it logs the error and
 * returns undefined.
 *
 * @param locations - An array of Valhalla_Location objects representing the waypoints of the route.
 * @param costing - The costing model to use for the route calculation. Defaults to "auto".
 * @returns A promise that resolves to either a Valhalla_RouteRes object if the route is successfully
 * fetched, a Valhalla_RouteError object if there is an error in the response, or undefined if an
 * exception occurs during the fetch operation.
 */
export async function fetchValhallaRoutePlan(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = "auto"
): Promise<Valhalla_RouteRes | Valhalla_RouteError | undefined> {
  try {
    const json = {
      locations: locations,
      costing: costing,
    };

    const res = await fetch(
      `${valhallaBaseUrl}route?json=${JSON.stringify(json)}`
    );
    const convertedResponse = await res.json();
    return convertedResponse;
  } catch (error) {
    console.log("fetchValhallaRoutePlan errored with", error);
  }
}
