import { keycloak } from "@/main";
import directus, {
  directusBaseURL,
  passedSSOName,
} from "../../clients/directusClient";
import { notifyAnalytics } from "./helperFunctions";

/**
 * API Call to check if current token exists and is valid.
 * This will authenticate the directus connection if successful.
 *
 * @returns `true` if token auth successful `false` if failed.
 */
export async function checkToken(): Promise<boolean> {
  let authenticated = false;

  // Try to authenticate
  await directus.auth
    .refresh()
    .then(() => {
      authenticated = true;
    })
    .catch((err: unknown) => {
      // The user is not authenticated
      window.location.href =
        directusBaseURL +
        "/auth/login/" +
        (passedSSOName ?? "sso") +
        "?redirect=" +
        window.location.origin +
        window.location.pathname;
      // no notifying of analytics as this is expected to fail it token is not present or expired.
      if (process.env.NODE_ENV === "development") console.log(err);
    });

  // token failed to auth
  return authenticated;
}

/**
 * API Call to logout the user.
 * This will invalidate the directus connection if successful and is a fire and forget style function.
 */
export async function logout() {
  try {
    await directus.auth.logout();
    directus.storage.delete("auth_refresh_token");
    directus.storage.delete("auth_expires_at");
    await keycloak.logout();
  } catch (error) {
    notifyAnalytics(error as Error, "logout");
  }
}
