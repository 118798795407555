<template>
  <v-container class="px-5 background">
    <v-card-title class="tertiary--text pt-5 d-flex justify-space-between px-0">
      <span> Favourite locations </span>
      <!-- add new location button -->
      <ElevatedBtn @click="showAddDialog = true">Add a location</ElevatedBtn>
    </v-card-title>
    <!-- fav location list -->
    <FavLocationCard
      v-for="favLocation in favLocations"
      :key="favLocation.localId"
      :favLocation="favLocation"
      @remove="deleteFavLocation"
      @edit="editFavLocation"
    />
    <!-- dialogs -->
    <FavLocationAddDialog
      :activator="showAddDialog"
      @close="showAddDialog = false"
    />
    <FavLocationEditDialog
      :activator="showEditDialog"
      :favLocation="editingFavLocation"
      @close="showEditDialog = false"
    />
  </v-container>
</template>
<script lang="ts">
import { MutationTypes, type State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapState } from "vuex";
import FavLocationCard from "./FavLocationCard.vue";
import FavLocationAddDialog from "../dialog-content/FavLocationAddDialog.vue";
import FavouriteLocation from "@/logic/classes/favouriteLocation";
import FavLocationEditDialog from "../dialog-content/FavLocationEditDialog.vue";
import { deleteFavouriteLocation } from "@/logic/api/calls/directus_calls/favouriteLocationsCalls";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
export default Vue.extend({
  name: "FavLocationsMain",
  computed: {
    ...mapState({
      favLocations: (state: unknown) => (state as State).favLocations,
    }),
  },
  components: {
    FavLocationCard,
    FavLocationAddDialog,
    FavLocationEditDialog,
    ElevatedBtn,
  },
  data() {
    return {
      showAddDialog: false,
      showEditDialog: false,
      editingFavLocation: undefined as FavouriteLocation | undefined,
    };
  },
  methods: {
    /**
     * Edits a favourite location by opening the edit dialog and setting the
     * editingFavLocation data property to the provided favourite location.
     *
     * @param {FavouriteLocation} favLocation - The favourite location to edit.
     * @return {void} This function does not return anything.
     */
    editFavLocation(favLocation: FavouriteLocation): void {
      this.editingFavLocation = favLocation;
      this.showEditDialog = true;
    },
    /**
     * Deletes a favourite location from the store.
     *
     * @param {FavouriteLocation} favLocation - The favourite location to delete.
     * @return {void} This function does not return anything.
     */
    deleteFavLocation(favLocation: FavouriteLocation): void {
      this.$store.commit(MutationTypes.deleteFavLocation, favLocation.localId);
      if (favLocation.directusId) {
        deleteFavouriteLocation(favLocation.directusId);
      }
    },
  },
});
</script>
