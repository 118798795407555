<template>
  <v-card class="pa-4 mb-5 rounded-lg" elevation="2">
    <v-card-title>
      <v-spacer></v-spacer>
      <ElevatedBtn
        @click="handelAddStop"
        :disabled="
          !addressData ||
          !!weightChangeError ||
          !!addressError ||
          addressLoading
        "
      >
        Add Stop
      </ElevatedBtn>
    </v-card-title>
    <v-card-text>
      <AddressAutocompleteInput
        :initialValue="addressData"
        @update="handleAddressUpdate"
        allowFavLocations
        placeholder="Search for stop address..."
        :errorMsg="addressError"
        :loading="addressLoading"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Duration
      <v-spacer></v-spacer>
      <StyledToolTip v-if="durationTooltip" :data="durationTooltip" />
    </v-card-title>
    <v-card-text>
      <StayDurationInput
        @update="handleStayDurationChange"
        :initialValue="stay"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Charging
      <v-spacer></v-spacer>
      <StyledToolTip v-if="chargingTooltip" :data="chargingTooltip" />
    </v-card-title>
    <v-card-text>
      <v-slider
        :label="`charge up to ${SOCAfterCharging}%`"
        track-color="grey lighten-2"
        color="primary"
        v-model="SOCAfterCharging"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Weight changes
      <v-spacer></v-spacer>
      <StyledToolTip v-if="weightChangeTooltip" :data="weightChangeTooltip" />
    </v-card-title>
    <v-card-text>
      <BatteryLikeInput
        wholeNumber
        allowNegatives
        :passedValue="weightChange"
        @update="handleWeightSet"
        :errorMessages="weightChangeError"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Energy use
      <v-spacer></v-spacer>
      <StyledToolTip v-if="energyUseTooltip" :data="energyUseTooltip" />
    </v-card-title>
    <v-card-text>
      <v-slider
        :label="`${usedEnergy / 100}kWh`"
        track-color="grey lighten-2"
        color="primary"
        v-model="usedEnergy"
        min="0"
        max="100"
      />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "@/ui/components/ui-elements/inputs/AddressAutocompleteInput.vue";
import StayDurationInput, {
  StayDurationInputUpdateObj,
} from "../../ui-elements/inputs/StayDurationInput.vue";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import BatteryLikeInput from "../../ui-elements/inputs/BatteryLikeInput.vue";
import { quickTripCheck } from "@/logic/api/calls/valhalla_calls";
import { QuickTripCheckReturn } from "@/logic/types/valhalla_types";
import { mapState } from "vuex";
import { State } from "@/logic/store/store_types";
import StyledToolTip from "../../ui-elements/StyledToolTip.vue";
export default Vue.extend({
  name: "WaypointPlanningCard",
  props: {
    remainingLoad: {
      type: Number,
      default: 0,
    },
    originCoordinates: {
      type: Object as PropType<{ lat: number; lon: number }>,
    },
    destinationCoordinates: {
      type: Object as PropType<{ lat: number; lon: number }>,
    },
  },
  components: {
    AddressAutocompleteInput,
    StayDurationInput,
    ElevatedBtn,
    BatteryLikeInput,
    StyledToolTip,
  },
  data() {
    return {
      addressData: undefined as processedAddressObj | undefined,
      addressLoading: false,
      addressError: null as string | null,
      stay: 0, // seconds
      SOCAfterCharging: 0, // %
      weightChange: 0, // kg
      weightChangeError: undefined as string | undefined,
      usedEnergy: 0, // percentage of 1 kWh
    };
  },
  computed: {
    ...mapState({
      favLocations: (state: unknown) => (state as State).favLocations,
      durationTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) =>
            content.Description === "additional stop duration tooltip"
        ),
      chargingTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) =>
            content.Description === "additional stop charging tooltip"
        ),
      weightChangeTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) =>
            content.Description === "additional stop weight change tooltip"
        ),
      energyUseTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) =>
            content.Description === "additional stop energy use tooltip"
        ),
    }),
  },
  methods: {
    handleStayDurationChange(val: StayDurationInputUpdateObj): void {
      this.stay = val.duration;
    },
    handleAddressUpdate(val: AddressAutocompleteInputUpdateObj): void {
      this.addressData = val.addressData;
      if (val.addressData?.localId) {
        const favLocationData = this.favLocations.find(
          (loc) => loc.localId === val.addressData?.localId
        );
        if (!favLocationData) return;
        if (favLocationData.planningData?.loadWeightChange) {
          this.weightChange = favLocationData.planningData.loadWeightChange;
        }
        if (favLocationData.planningData?.energyUsed) {
          this.usedEnergy = favLocationData.planningData.energyUsed;
        }
        if (favLocationData.planningData?.SOCAfterCharging) {
          this.SOCAfterCharging = favLocationData.planningData.SOCAfterCharging;
        }
        if (favLocationData.planningData?.stayDuration) {
          this.stay = favLocationData.planningData.stayDuration;
        }
      }
    },
    handelAddStop(): void {
      if (!this.addressData) return;
      const stop = new TripLocation({
        address: this.addressData.address,
        coordinates: new Coordinate({
          latitude: this.addressData.coordinates.Latitude,
          longitude: this.addressData.coordinates.Longitude,
        }),
        name: this.addressData.name,
        stay: this.stay ?? undefined,
        weightChange: this.weightChange ?? undefined,
        nonDrivingEnergyUsed: this.usedEnergy ?? undefined,
        stateOfChargeAfterCharging: this.SOCAfterCharging ?? undefined,
      });
      this.$emit("add-stop", stop);
      this.resetForm();
    },
    handleWeightSet(val: number) {
      // validation
      if (this.remainingLoad + val < 0) {
        this.weightChangeError =
          "you can't off load more than you are carrying";
      } else {
        this.weightChangeError = undefined;
      }
      // update sate
      this.weightChange = val;
    },
    resetForm(): void {
      this.addressData = undefined;
      this.stay = 0;
      this.SOCAfterCharging = 0;
      this.weightChange = 0;
      this.usedEnergy = 0;
    },
  },
  watch: {
    async addressData(val: processedAddressObj | undefined) {
      if (!val) {
        this.addressError = null;
        return;
      }
      if (val) {
        this.addressLoading = true;
        const quickCheckRes = await quickTripCheck([
          this.originCoordinates,
          {
            lat: val.coordinates.Latitude,
            lon: val.coordinates.Longitude,
          },
          this.destinationCoordinates,
        ]);
        if (quickCheckRes === QuickTripCheckReturn.routable) {
          // clear address errors as routable.
          this.addressError = null;
        } else if (quickCheckRes === QuickTripCheckReturn.unconnected_regions) {
          this.addressError = "Stop is in an unconnected region";
        } else if (quickCheckRes === QuickTripCheckReturn.not_routable) {
          this.addressError = "Stop cannot be reached";
        }
        this.addressLoading = false;
      }
    },
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
