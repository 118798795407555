<template>
  <v-card @click="emitClick" class="rounded-lg background">
    <v-card-title>
      {{ vehicle.name ?? "unnamed vehicle" }}
    </v-card-title>
    <v-card-subtitle class="font-weight-medium pr-12">
      {{ vehicle.licensePlate ?? "unrecorded license plate" }}
    </v-card-subtitle>
    <v-card-text
      v-if="
        vehicle.fuelType === 'Electric' || vehicle.fuelType === 'Plug in hybrid'
      "
    >
      <NotConnectedBatteryDisplay v-if="!vehicle.stateOfCharge" />
      <BatteryDisplay v-else :battery="vehicle.stateOfCharge" />
      {{ vehicle.fuelType ? vehicle.fuelType : "Electric" }}
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import BatteryDisplay from "../ui-elements/BatteryDisplay.vue";
import NotConnectedBatteryDisplay from "../ui-elements/NotConnectedBatteryDisplay.vue";

export default Vue.extend({
  name: "VehicleListCard",
  components: {
    BatteryDisplay,
    NotConnectedBatteryDisplay,
  },
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
  },
  methods: {
    /**
     * Emits a click event to the parent component.
     *
     * @return {void} No return value
     */
    emitClick() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
