import { PartialItem, QueryMany } from "@directus/sdk";
import Vue from "vue";
import directus, { CollectionNames } from "../../clients/directusClient";

/**
 * Passes an exception event on to our countly analytics server.
 *
 * @param error the `Error` object for the exception
 * @param functionName the function name or description string to locate function that generated the exception.
 */
export function notifyAnalytics(error: Error, functionName: string) {
  if (process.env.NODE_ENV === "development")
    console.log(
      "#ERROR DEV NOTIFICATION: `" + functionName + "` errored with",
      error
    );
  Vue.prototype.$Countly.q.push([
    "recordError",
    { stack: error.message },
    true,
    {
      type: "api call",
      filePath: "src\\logic\\api\\calls\\directus_calls",
      functionName,
    },
  ]);
}

/**
 * Fetches a list of items from directus.
 *
 * @param collection the name of the directus collection
 * @param query the directus query to use with this request
 * @returns a list of items of the type defined in passed type <T> if successful else undefined if errored.
 */
export async function directusReadByQuery<T>(
  collection: CollectionNames,
  query: QueryMany<unknown>
): Promise<T[] | undefined> {
  try {
    const res = await directus.items(collection).readByQuery(query);
    return res.data as T[];
  } catch (error) {
    notifyAnalytics(
      error as Error,
      `directusReadByQuery targeting collection "${collection}"`
    );
    return;
  }
}

/**
 * Fetches a single item from directus.
 *
 * @param collection the name of the directus collection
 * @param id the id of the item
 * @param query optional - the directus query to use with this request
 * @returns a single item of the type defined in passed type <T> if successful else undefined if errored.
 */
export async function directusReadOne<T>(
  collection: CollectionNames,
  id: string | number,
  query?: QueryMany<unknown>
): Promise<T | undefined> {
  try {
    const res = await directus.items(collection).readOne(id, query);
    return res as T;
  } catch (error) {
    notifyAnalytics(
      error as Error,
      `directusReadOne targeting collection "${collection}" and item "${id}"`
    );
    return;
  }
}

/**
 * Creates a new item in a directus collection.
 *
 * @param collection the name of the directus collection.
 * @param item the object to be created can be an empty object if intending directus to apply defaults to the item.
 * @param query optional - the directus query to use with this request (alters the returned item by query).
 * @returns a single item of the type defined in passed type <T> if successful else undefined if errored.
 */
export async function directusCreateOne<T>(
  collection: CollectionNames,
  item: PartialItem<T>,
  query?: QueryMany<unknown>
): Promise<T | undefined> {
  try {
    const res = await directus.items(collection).createOne(item, query);
    return res as T;
  } catch (error) {
    notifyAnalytics(
      error as Error,
      `directusCreateOne targeting collection "${collection}"`
    );
    return;
  }
}

/**
 * Deletes a single item from a directus collection.
 *
 * @param collection the name of the directus collection.
 * @param id the id of the item to be deleted
 * @returns "ok" if successfully deleted or "failed" if not
 */
export async function directusDeleteOne(
  collection: CollectionNames,
  id: string | number
): Promise<"ok" | "failed"> {
  try {
    await directus.items(collection).deleteOne(id);
    return "ok";
  } catch (error) {
    notifyAnalytics(
      error as Error,
      `deleteOne targeting collection "${collection}" and item "${id}"`
    );
    return "failed";
  }
}

/**
 * Updated a single item in a directus collection.
 *
 * @param collection the name of the directus collection.
 * @param id the id of the item to be updated.
 * @param item the partial item object fo the item containing the fields that need to be updated.
 * @param query optional - the directus query to use with this request (alters the returned item by query).
 * @returns the updated item if successful undefined if not.
 */
export async function directusUpdateOne<T>(
  collection: CollectionNames,
  id: string | number,
  item: PartialItem<T>,
  query?: QueryMany<unknown>
): Promise<T | undefined> {
  try {
    const res = await directus.items(collection).updateOne(id, item, query);
    return res as T;
  } catch (error) {
    notifyAnalytics(
      error as Error,
      `deleteOne targeting collection "${collection}" and item "${id}"`
    );
    return;
  }
}

/**
 * Cleans a passed record/partial record item and removes the system generated properties directus will handle the updating of.
 *
 * @param data full/partial item.
 * @returns cleaned partial item.
 */
export function cleanOutSystemGeneratedProperties<T extends Directus_base_item>(
  data: PartialItem<T>
): PartialItem<T> {
  // Ensure no system generate properties that should be only auto updated are included.
  const preppedData = {
    ...data,
  };

  if (Object.hasOwn(preppedData, "id")) delete preppedData.id;
  if (Object.hasOwn(preppedData, "user_created"))
    delete preppedData.user_created;
  if (Object.hasOwn(preppedData, "date_created"))
    delete preppedData.date_created;
  if (Object.hasOwn(preppedData, "user_updated"))
    delete preppedData.user_updated;
  if (Object.hasOwn(preppedData, "date_updated"))
    delete preppedData.date_updated;
  if (Object.hasOwn(preppedData, "sort")) delete preppedData.sort;

  return preppedData;
}

interface Directus_base_item {
  id?: string | number;
  user_created?: string | null;
  date_created?: string | null;
  user_updated?: string | null;
  date_updated?: string | null;
  sort?: number | null;
}
