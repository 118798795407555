import Trip from "../classes/trip_classes/trip";
import OptimiserDefaultData from "../data/optimiserDefaultData";
import { CalcVsType } from "../store/store_types";
import { TripFrequency } from "../types/trip_specific_types";
import to2DP from "./to2DP";

/** the estimated totals over one year based on the stored trips and there
 * expected frequency over this period. */
export interface YearsSavings {
  /** The total expected cost if only public charging over the year. */
  totalPublicChargingCost: number;
  /** The total expected cost of private charging if not using public networks
   * over the year. */
  totalPrivateChargingCost: number;
  /** The total expected cost of fuel over the year if not driving and EV. */
  totalEquivalentFuelCost: number;
  /** The total avoided CO2 emissions expected over the year. */
  totalAvoidedEmissions: number;
  /** The total number of trips expected over the year. */
  totalNumberOfTrips: number;
  /** The total cost of expected EV maintenance over the year */
  totalEVMaintenanceCost: number;
  /** The total cost of expected petrol vehicle maintenance over the year */
  totalPetrolMaintenanceCost: number;
  /** The expected total cost of road user charges for a diesel vehicle based on the predicted distance traveled*/
  totalDieselRoadUserCharges: number;
  /** The expected total cost of road user charges for a petrol vehicle based on the predicted distance traveled*/
  totalPetrolRoadUserCharges: number;
  /** The expected total cost of road user charges for a electric vehicle based on the predicted distance traveled*/
  totalElectricRoadUserCharges: number;
}

export interface YearsSavingsSettings {
  perAnnumMaintenanceEV: number;
  perAnnumMaintenanceCurrentV: number;
  kWhCostHome: number;
  kWhCostPublic: number;
  calcVs: CalcVsType;
  petrolCostPerLitre: number;
  dieselCostPerLitre: number;
  petrolKmPerLitre: number;
  dieselKmPerLitre: number;
  dieselRoadUserCharges: number;
  petrolRoadUserCharges: number;
  electricRoadUserCharges: number;
}

/**
 * Calculates and returns the years savings base on the estimated cost and
 * amount of times all stored trips are expected to accumulate over a year
 * period.
 *
 * @param storedTrips the full `storedRoutePlans` array. See `routePlaningStore`.
 * @param settings the whole settings store global state object. See `settingsStore`.
 * @returns returns a calculated `YearsSavings` object.
 */
export default function calcYearsSavings(
  storedTrips: Trip[],
  settings: YearsSavingsSettings
): YearsSavings {
  // create accumulating variables
  let totalPublicChargingCost = 0;
  let totalPrivateChargingCost = 0;
  let totalEquivalentFuelCost = 0;
  let totalAvoidedEmissions = 0;
  let totalNumberOfTrips = 0;
  let totalDieselRoadUserCharges = 0;
  let totalPetrolRoadUserCharges = 0;
  let totalElectricRoadUserCharges = 0;

  // filter out one off trips
  const filteredTrips = storedTrips.filter((trip) => !!trip.frequency);

  filteredTrips.forEach((trip) => {
    // calculate this trips over five years totals
    const numberOfTrips = trip.frequency
      ? calcFrequencyAdjustment(trip.frequency)
      : 0;
    if (!numberOfTrips) return;
    const stats = trip.getTripStats({
      calcVs: settings.calcVs,
      petrolKmPerLitre: settings.petrolKmPerLitre,
      petrolCostPerLitre: settings.petrolCostPerLitre,
      dieselKmPerLitre: settings.dieselKmPerLitre,
      dieselCostPerLitre: settings.dieselCostPerLitre,
      kWhCostHome: settings.kWhCostHome,
    });
    if (!stats) return;
    const publicChargingCost =
      estimatePublicChargingCost({
        kWhCostPublic: settings.kWhCostPublic,
        totalTripEnergy: stats.totalEnergyUsed ?? 0,
        batteryCapacity:
          trip.vehicle_data?.totalBatteryKWh() ?? OptimiserDefaultData.battery,
      }) * numberOfTrips;
    const privateChargingCost =
      estimatePrivateChargingCost({
        kWhCostHome: settings.kWhCostHome,
        totalTripEnergy: stats.totalEnergyUsed ?? 0,
        batteryCapacity:
          trip.vehicle_data?.totalBatteryKWh() ?? OptimiserDefaultData.battery,
      }) * numberOfTrips;
    const equivalentFuelCost = (stats.fuelCost ?? 0) * numberOfTrips;
    const avoidedEmissions = (stats.avoidedCO2 ?? 0) * numberOfTrips;
    const dieselRoadUserCharges =
      calcRoadUserCharges(
        trip.itinerary.totalDrivingDistance,
        settings.dieselRoadUserCharges
      ) * numberOfTrips;
    const petrolRoadUserCharges =
      calcRoadUserCharges(
        trip.itinerary.totalDrivingDistance,
        settings.petrolRoadUserCharges
      ) * numberOfTrips;
    const electricRoadUserCharges =
      calcRoadUserCharges(
        trip.itinerary.totalDrivingDistance,
        settings.electricRoadUserCharges
      ) * numberOfTrips;

    // set accumulatingVariables
    totalPublicChargingCost += publicChargingCost;
    totalPrivateChargingCost += privateChargingCost;
    totalEquivalentFuelCost += equivalentFuelCost;
    totalAvoidedEmissions += avoidedEmissions;
    totalNumberOfTrips += numberOfTrips;
    totalDieselRoadUserCharges += dieselRoadUserCharges;
    totalPetrolRoadUserCharges += petrolRoadUserCharges;
    totalElectricRoadUserCharges += electricRoadUserCharges;
  });

  return {
    totalPublicChargingCost: to2DP(totalPublicChargingCost),
    totalPrivateChargingCost: to2DP(totalPrivateChargingCost),
    totalEquivalentFuelCost: to2DP(totalEquivalentFuelCost),
    totalAvoidedEmissions: Math.round(totalAvoidedEmissions),
    totalNumberOfTrips: Math.round(totalNumberOfTrips),
    totalEVMaintenanceCost: to2DP(settings.perAnnumMaintenanceEV),
    totalPetrolMaintenanceCost: to2DP(settings.perAnnumMaintenanceCurrentV),
    totalDieselRoadUserCharges: to2DP(totalDieselRoadUserCharges),
    totalPetrolRoadUserCharges: to2DP(totalPetrolRoadUserCharges),
    totalElectricRoadUserCharges: to2DP(totalElectricRoadUserCharges),
  };
}

/**
 * Calculates and returns the total number of times this trip is expected to be taken
 * over a one year period.
 *
 * @param frequency the stored trips `TripFrequency` object. See 'types' for details.
 * @returns the total number of trips over one years.
 */
export function calcFrequencyAdjustment(frequency: TripFrequency): number {
  let timeFrameAdjustment = 0;

  switch (frequency.timeFrame) {
    case "day":
      // approximate result, 365.3.
      // This should cover leap year in the time frame without
      // exact knowledge of the year.
      timeFrameAdjustment = 365.3;
      break;
    case "week":
      timeFrameAdjustment = 52;
      break;
    case "weekday":
      timeFrameAdjustment = 260;
      break;
    case "fortnight":
      timeFrameAdjustment = 26;
      break;
    case "month":
      timeFrameAdjustment = 12;
      break;
    case "quarter":
      timeFrameAdjustment = 4;
      break;
    case "half-year":
      timeFrameAdjustment = 2;
      break;
    case "year":
      timeFrameAdjustment = 1;
      break;
  }

  return timeFrameAdjustment * frequency.numberOfTimes;
}

/**
 * Calculates and returns the total cost of road user charges for a single run of this trip.
 *
 * @param distance_meters the number of meters traveled in a single run of this trip.
 * @param costPer1000km the cost per 1000km for the target type of vehicle.
 * @returns the total cost of road user charges for a single run of this trip.
 */
function calcRoadUserCharges(
  distance_meters: number,
  costPer1000km: number
): number {
  const distance_km = distance_meters / 1000;
  // n = $ per 1000km
  return (distance_km / 1000) * costPer1000km;
}

function estimatePrivateChargingCost({
  kWhCostHome,
  totalTripEnergy,
  batteryCapacity,
}: {
  kWhCostHome: number;
  totalTripEnergy: number;
  batteryCapacity: number;
}): number {
  // Cost per kWh of electricity for private charging (e.g. at home) * MIN ( trip kWh, vehicle usable battery capacity).
  return kWhCostHome * Math.min(totalTripEnergy, batteryCapacity);
}

function estimatePublicChargingCost({
  kWhCostPublic,
  totalTripEnergy,
  batteryCapacity,
}: {
  kWhCostPublic: number;
  totalTripEnergy: number;
  batteryCapacity: number;
}): number {
  // Cost per kWh of electricity in public * MAX ( 0 , trip kWh - vehicle usable battery capacity ).
  return kWhCostPublic * Math.max(0, totalTripEnergy - batteryCapacity);
}
