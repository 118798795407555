<template>
  <v-card>
    <v-card-title class="mb-5">
      <v-icon color="primary" class="mr-3"> mdi-piggy-bank-outline </v-icon>
      ${{
        (
          (oneYearProjections.totalEquivalentFuelCost +
            (calcVs === "PETROL"
              ? oneYearProjections.totalPetrolRoadUserCharges
              : oneYearProjections.totalDieselRoadUserCharges) +
            oneYearProjections.totalPetrolMaintenanceCost -
            oneYearProjections.totalPublicChargingCost -
            oneYearProjections.totalPrivateChargingCost -
            oneYearProjections.totalEVMaintenanceCost -
            oneYearProjections.totalElectricRoadUserCharges) *
          years
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }}
    </v-card-title>
    <v-card-text>
      Estimated savings over 5 years
      <v-card class="background mt-5" flat>
        <v-icon color="primary" class="mr-1 px-5 py-2"> mdi-fire </v-icon>
        <span>
          {{
            Math.round(
              oneYearProjections.totalAvoidedEmissions * years
            ).toLocaleString()
          }}
          kg CO₂ emissions avoided
        </span>
      </v-card>
      <v-card class="background mt-5" flat>
        <v-icon color="primary" class="mr-1 px-5 py-2"> mdi-pliers </v-icon>
        <span>
          ${{
            (
              (oneYearProjections.totalPetrolMaintenanceCost -
                oneYearProjections.totalEVMaintenanceCost) *
              years
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
          maintenance savings
        </span>
      </v-card>
      <v-card class="background mt-5" flat>
        <v-icon color="primary" class="mr-1 px-5 py-2">
          mdi-water-outline
        </v-icon>
        <span>
          ${{
            (
              (oneYearProjections.totalEquivalentFuelCost +
                oneYearProjections.totalPublicChargingCost -
                oneYearProjections.totalPrivateChargingCost) *
              years
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
          fuel savings
        </span>
      </v-card>
      <OutlinedBlockBtn class="mt-5 mb-2 rounded-pill" @click="toSavedTrips">
        See all saved trips & estimated savings
      </OutlinedBlockBtn>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import OutlinedBlockBtn from "@/ui/components/ui-elements/buttons/OutlinedBlockBtn.vue";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "FrequentTripSavingsOverview",
  components: { OutlinedBlockBtn },
  computed: {
    ...mapGetters({
      oneYearProjections: GettersTypes.getOneYearProjections,
    }),
    ...mapState({
      calcVs: (state: unknown) => (state as State).calcVs,
    }),
  },
  data() {
    return {
      years: 5,
    };
  },
  methods: {
    /**
     * Redirects to saved trips page from frequent trip savings overview.
     * Opens the saved trips dialog in the dashboard.
     */
    toSavedTrips() {
      this.$store.commit(
        MutationTypes.setDashboardDialogContent,
        "saved-trips"
      );
      this.$store.commit(MutationTypes.setShowDashboard, true);
    },
  },
});
</script>
