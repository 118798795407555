<template>
  <v-card flat class="background pt-5">
    <v-card-text>
      <SelectedComparisonVehicleCard :vehicle="vehicle" />
    </v-card-text>
    <v-card-text>
      <BookingCalendar :asset-id="vehicle.directusId" :asset-type="'vehicle'" />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import BookingCalendar from "./BookingCalendar.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import SelectedComparisonVehicleCard from "../vehicles/SelectedComparisonVehicleCard.vue";
export default Vue.extend({
  name: "VehicleBookingContent",
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
  },
  components: { BookingCalendar, SelectedComparisonVehicleCard },
});
</script>
