<template>
  <v-card flat class="background">
    <v-card-text class="background pa-5">
      <!-- Conditionally rendered trip stats section if a trip is selected. -->
      <v-card flat v-if="tripStats">
        <!-- Performance -->
        <v-card class="rounded-lg mb-3">
          <V-card-title>Performance summary</V-card-title>
          <v-card-text>
            <v-row no-gutters class="mb-3">
              <v-icon color="primary" class="mr-1"> mdi-clock-outline </v-icon>
              {{ tripStats.totalTime }}
              driving time
            </v-row>
            <v-row no-gutters>
              <v-icon color="primary" class="mr-1">
                mdi-map-marker-radius-outline
              </v-icon>
              {{ Math.round(trip.itinerary.totalDrivingDistance / 1000) }} km
              distance traveled
            </v-row>
          </v-card-text>
          <v-card-title class="text-body-1 font-weight-medium">
            Charging time
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-card class="background pa-2 mr-2 flex-grow-1 rounded-lg" flat>
                <v-row no-gutters>
                  <v-icon color="primary" class="mr-1">
                    mdi-clock-outline
                  </v-icon>
                  <span class="primary--text mr-1 font-weight-medium">AC</span>
                  <span class="font-weight-regular">
                    {{ niceDuration(acDcSplit ? acDcSplit.acChargingTime : 0) }}
                  </span>
                </v-row>
              </v-card>
              <v-card class="background pa-2 ml-2 flex-grow-1 rounded-lg" flat>
                <v-row no-gutters>
                  <v-icon color="primary" class="mr-1">
                    mdi-clock-outline
                  </v-icon>
                  <span class="primary--text mr-1 font-weight-medium">DC</span>
                  <span class="font-weight-regular">
                    {{ niceDuration(acDcSplit ? acDcSplit.dcChargingTime : 0) }}
                  </span>
                </v-row>
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-title class="text-body-1 font-weight-medium">
            Battery usage
          </v-card-title>
          <v-card-text>
            <v-row no-gutters class="mb-3" v-if="tripStats.battery">
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1"> mdi-ev-station </v-icon>
                {{ compileNumChargesString(tripStats.battery) }}
              </v-card>
            </v-row>
            <v-row no-gutters class="mb-3">
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1"> mdi-battery-plus </v-icon>
                {{ Math.round(tripStats.battery) }}% of battery capacity used
              </v-card>
            </v-row>
            <v-row no-gutters>
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1">
                  mdi-lightning-bolt
                </v-icon>
                {{ Math.round(tripStats.totalEnergyUsed) }} kWh needed to
                complete this trip
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- Savings -->
        <v-card class="rounded-lg mb-3">
          <v-card-title>
            <v-icon color="primary" class="mr-1">
              mdi-piggy-bank-outline
            </v-icon>
            ${{
              (
                tripStats.fuelCost -
                tripStats.publicChargingCost -
                (tripStats.privateChargingCost || 0)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}
            fuel savings
          </v-card-title>
          <v-card-title class="text-body-1 font-weight-medium">
            Cost estimates
          </v-card-title>
          <v-card-text>
            <v-row no-gutters class="mb-3">
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1">
                  mdi-card-account-details-outline
                </v-icon>
                ${{
                  (tripStats.roadUserCharges ?? 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                road user charges
              </v-card>
            </v-row>
            <v-row no-gutters class="mb-3">
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1"> mdi-currency-usd </v-icon>
                ${{ tripStats.privateChargingCost || "0.00" }} slow charging
                cost
              </v-card>
            </v-row>
            <v-row no-gutters>
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1"> mdi-currency-usd </v-icon>
                ${{ tripStats.publicChargingCost }} fast charging cost
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-title class="text-body-1 font-weight-medium">
            Cost estimates
          </v-card-title>
          <v-card-text>
            <v-row no-gutters class="mb-3">
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1"> mdi-currency-usd </v-icon>
                ${{ tripStats.privateChargingCost || "0.00" }} equivalent fuel
                cost
              </v-card>
            </v-row>
            <v-row no-gutters>
              <v-card class="background pa-2 rounded-lg flex-grow-1" flat>
                <v-icon color="primary" class="mr-1">
                  mdi-piggy-bank-outline
                </v-icon>
                ${{
                  (
                    tripStats.fuelCost -
                    tripStats.publicChargingCost -
                    (tripStats.privateChargingCost || 0)
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                fuel savings
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
      <!-- Conditionally rendered default section if no selected trip. -->
      <v-card flat v-else>
        <v-card-text class="px-0">
          You currently have no trip selected{{
            !hasStoredTrips ? " and no stored trips" : ""
          }}. Please add a new trip{{
            hasStoredTrips ? " or select one of your stored trips" : ""
          }}.
        </v-card-text>
        <v-card-actions class="px-0">
          <ElevatedBtn @click="handleViewTripsClick">
            View your trips
          </ElevatedBtn>
          <OutlinedBtn class="flex-grow-1" @click="openNewTripForm">
            Plan a new trip
          </OutlinedBtn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import { GettersTypes, State } from "@/logic/store/store_types";
import calcTripAcDcSplit, {
  TripAcDcSplit,
} from "@/logic/utils/calcTripAcDcSplit";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";
import OutlinedBtn from "@/ui/components/ui-elements/buttons/OutlinedBtn.vue";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

/** `Vue component:` renders trip statistics to be displayed
 * in the `MainDialog` and `MobileDialog` components*/
export default Vue.extend({
  name: "TripStatsContent",
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
      tripStats: GettersTypes.selectedTripStats,
    }),
    ...mapState({
      hasStoredTrips: (state: unknown) => !!(state as State).trips.length,
      isShowingAll: (state: unknown) =>
        (state as State).displayAllChargersAlongRoute,
      privateChargingCost: (state: unknown) =>
        (state as State).defaultHomeCostPerKWh,
      calcVs: (state: unknown) => (state as State).calcVs,
      loadingAllChargers: (state: unknown) =>
        (state as State).radarFetchingFlag,
      chargers: (state: unknown) => (state as State).chargers,
    }),
    acDcSplit(): TripAcDcSplit | undefined {
      if (!this.trip) return undefined;
      return calcTripAcDcSplit(this.trip, this.chargers);
    },
  },
  methods: {
    prettyTime(seconds: number): string {
      return seconds + "seconds";
    },
    handleViewTripsClick() {
      this.$router.push({ name: RouteNames.trips });
    },
    openNewTripForm() {
      this.$router.push({ name: RouteNames.tripAddDestination });
    },
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds, true);
    },
    compileNumChargesString(battery: string | undefined): string {
      if (!battery) return "unknown";
      const parsedBattery = parseIntOrFloat(battery);
      if (!parsedBattery) return "unknown";
      if (isNaN(parsedBattery)) return "unknown";
      const numberOfChargesNeeded = parsedBattery / 80;
      if (numberOfChargesNeeded % 1 === 0) {
        return (
          numberOfChargesNeeded +
          " charging stop" +
          (numberOfChargesNeeded > 1 ? "s" : "") +
          " required"
        );
      } else {
        return (
          Math.floor(numberOfChargesNeeded) +
          " - " +
          Math.ceil(numberOfChargesNeeded) +
          " charging stops required"
        );
      }
    },
  },
  components: {
    ElevatedBtn,
    OutlinedBtn,
  },
});
</script>
