<template>
  <v-card flat class="background">
    <v-card-title>Saved trips</v-card-title>
    <v-card-text>
      <v-slide-group>
        <v-slide-item v-for="(trip, index) in savedTrips" :key="index">
          <v-card
            style="max-width: 200px"
            class="mb-2 mr-2 pa-5 rounded-lg background"
            @click="fetching ? null : showTrip(trip)"
            :loading="fetching"
            :disabled="fetching"
          >
            <v-card-title class="text-truncate">
              <span class="text-truncate">{{ trip.name }}</span>
            </v-card-title>
            <v-card-subtitle v-if="trip.itinerary.destination">
              <span class="text-truncate">
                Trip to {{ trip.itinerary.destination.name }}
              </span>
            </v-card-subtitle>
            <v-card-text>
              <p class="mb-0">{{ niceDuration(trip.itinerary.totalTime) }}</p>
              <p class="mb-0">
                {{ trip.chargingStopCDBIDs.length }} charging stops
              </p>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import { ActionTypes, GettersTypes } from "@/logic/store/store_types";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import Vue from "vue";
export default Vue.extend({
  name: "SavedTripCarousel",
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
    fetching(): boolean {
      return this.$store.getters[GettersTypes.fetching];
    },
  },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    showTrip(trip: Trip) {
      this.$store.dispatch(ActionTypes.showTrip, trip);
      this.$emit("view-trip");
    },
  },
});
</script>
