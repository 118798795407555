<template>
  <v-card
    :color="bookings.length > 0 ? '#2EAEFF1A' : 'transparent'"
    class="rounded-lg mb-10 pa-4"
    flat
  >
    <v-row no-gutters align="center">
      <v-icon v-if="bookings.length > 0" color="#2E46ff" class="mr-2">
        mdi-bell-badge-outline
      </v-icon>
      <span v-if="bookings.length > 0"> {{ bookings.length }} Bookings </span>
      <v-spacer />
      <ElevatedBtn @click="showBooking = true">Book this vehicle</ElevatedBtn>
    </v-row>
    <!-- booking dialog -->
    <GenericDialog
      v-if="Vehicle && Vehicle.directusId"
      :activator="showBooking"
      @close="showBooking = false"
    >
      <VehicleBookingContent :vehicle="Vehicle" />
    </GenericDialog>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import ElevatedBtn from "../ui-elements/buttons/ElevatedBtn.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import {
  DirectusAssetBooking,
  fetchAssetBookingsByAssetID,
} from "@/logic/api/calls/directus_calls/assetBookingsCalls";
import GenericDialog from "../dialog-wrappers/GenericDialog.vue";
import VehicleBookingContent from "./VehicleBookingContent.vue";

export default Vue.extend({
  name: "VehicleBookingBand",
  props: {
    Vehicle: { type: Object as PropType<Vehicle>, required: true },
  },
  components: { ElevatedBtn, GenericDialog, VehicleBookingContent },
  methods: {
    async fetchBookingsData() {
      if (!this.Vehicle.directusId) return;
      const directusFormatEvents = await fetchAssetBookingsByAssetID(
        this.Vehicle.directusId
      );
      if (directusFormatEvents) this.bookings = directusFormatEvents;
    },
  },
  data() {
    return {
      bookings: [] as DirectusAssetBooking[],
      showBooking: false,
    };
  },
  mounted() {
    this.fetchBookingsData();
  },
  watch: {
    Vehicle() {
      this.fetchBookingsData();
    },
  },
});
</script>
