<template>
  <InfoPanelCardWrapper>
    <v-card flat>
      <!-- address search section -->
      <v-card flat class="mb-10">
        <v-card-text>
          <AddressAutocompleteInput
            placeholder="Search Optimiser for a location..."
            :initialValue="addressData"
            :allowFavLocations="true"
            @update="updateAddress"
          />
          <v-row no-gutters>
            <v-spacer />
            <ElevatedBtn @click="toPlanning"> Continue </ElevatedBtn>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- history preview section -->
      <v-card-title class="pt-5 tertiary--text">
        Unsaved trips
        <v-spacer></v-spacer>
        <StyledToolTip v-if="unsavedTripsTooltip" :data="unsavedTripsTooltip" />
      </v-card-title>
      <v-card-text>
        <UnsavedTripCarousel />
      </v-card-text>
      <v-card-title class="pt-5 tertiary--text">
        Saved trips
        <v-spacer></v-spacer>
        <StyledToolTip v-if="savedTripsTooltip" :data="savedTripsTooltip" />
      </v-card-title>
      <v-card-text>
        <FrequentTripSavingsOverview class="mb-5" />
        <SavedTripCard v-if="savedTrips.length" :trip="savedTrips[0]" />
        <SavedTripCard v-if="savedTrips.length >= 2" :trip="savedTrips[1]" />
        <SavedTripCard v-if="savedTrips.length >= 3" :trip="savedTrips[2]" />
      </v-card-text>
    </v-card>
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import InfoPanelCardWrapper from "@/ui/components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { GettersTypes, State } from "@/logic/store/store_types";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import ElevatedBtn from "../components/ui-elements/buttons/ElevatedBtn.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import { Dictionary } from "vue-router/types/router";
import Trip from "@/logic/classes/trip_classes/trip";
import FrequentTripSavingsOverview from "../components/trips/statistics/info-panel-content/FrequentTripSavingsOverview.vue";
import SavedTripCard from "../components/trips/history/SavedTripCard.vue";
import UnsavedTripCarousel from "../components/trips/history/UnsavedTripCarousel.vue";
import { mapState } from "vuex";
import StyledToolTip from "../components/ui-elements/StyledToolTip.vue";

export default Vue.extend({
  name: "HistoryMainView",
  components: {
    InfoPanelCardWrapper,
    ElevatedBtn,
    AddressAutocompleteInput,
    FrequentTripSavingsOverview,
    SavedTripCard,
    UnsavedTripCarousel,
    StyledToolTip,
  },
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
    unsavedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.unsavedTrips];
    },
    ...mapState({
      unsavedTripsTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) =>
            content.Description === "trip history explanation tooltip"
        ),
      savedTripsTooltip: (state: unknown) =>
        (state as State).managedContent.find(
          (content) => content.Description === "favourite trips tooltip"
        ),
    }),
  },
  methods: {
    // Routing
    pushRoute(
      routeName: RouteNames,
      query:
        | Dictionary<string | (string | null)[] | null | undefined>
        | undefined = undefined
    ) {
      this.$router.push({ name: routeName, query: query });
    },
    toPlanning() {
      const address = this.addressData?.address;
      const lat = this.addressData?.coordinates.Latitude;
      const lon = this.addressData?.coordinates.Longitude;
      const name = this.addressData?.name;
      const newQuery = {
        ...this.$route.query,
      };

      if (address) {
        newQuery.destAddress = encodeURI(address);
      }

      if (lat) {
        newQuery.destLat = lat.toString();
      }

      if (lon) {
        newQuery.destLon = lon.toString();
      }

      if (name) {
        newQuery.destName = name;
      }

      this.pushRoute(RouteNames.tripAddDestination, newQuery);
    },
    updateAddress(val: AddressAutocompleteInputUpdateObj) {
      this.addressData = val.addressData;
    },
  },
  data() {
    return {
      addressData: undefined as processedAddressObj | undefined,
    };
  },
});
</script>
