import { EVNavEnergy } from "@/logic/types/ev_nav_types";
import Vehicle from "../vehicle_classes/vehicle";
import generateUniqueLocalID from "@/logic/utils/generateUniqueLocalID";
import { TripBaseLeg } from "./trip";

interface TripComparisonOptions {
  /** The vehicle being compared against. */
  vehicle: Vehicle;
  /** EV nav energy data response for this vehicle doing the comparison trip */
  energyData: EVNavEnergy[];
  /**
   * Decimal representation of charge usable in the drivers selected min charge
   * to max charge range.
   *
   * This should be a number between 0 and 1.
   */
  chargeUsableInRange?: number;
  /** Maximum charging rate for the vehicle. Used to calculate charging time.*/
  maxChargingRate?: number;
  /** Minimum charging rate for the vehicle. Used to calculate charging time.*/
  minChargingRate?: number;
  /** Base legs used to calculate this comparisons energy usage. */
  baseLegs: TripBaseLeg[];
}

interface TripComparisonEstimatedChargingTime {
  /** Minimum charge rate. */
  ac_charging_rate: number;
  /** Maximum charge rate. */
  dc_charging_rate: number;
  /** Estimated charging time at min charge rate. */
  ac_charging_time: number;
  /** Estimated charging time at max charge rate. */
  dc_charging_time: number;
}

export default class TripComparison {
  // ----------------------------------------------------------------------- //
  // -------------------------- Global class state ------------------------- //
  // ----------------------------------------------------------------------- //

  /** global record of class instance ids this session. */
  static usedIds: string[] = [];

  // ----------------------------------------------------------------------- //
  // ------------------------------- State --------------------------------- //
  // ----------------------------------------------------------------------- //

  /** Unique id for this instance. */
  localId: string;
  /** The vehicle being compared against. */
  vehicle: Vehicle;
  /** EV nav energy data response for this vehicle doing the comparison trip */
  energyData: EVNavEnergy[];
  /**
   * Decimal representation of charge usable in the drivers selected min charge
   * to max charge range
   *
   * This should be a number between 0 and 1.
   */
  chargeUsableInRange = 0.8;
  /** Maximum charging rate for the vehicle in kw. Used to calculate charging time.*/
  maxChargingRate?: number;
  /** Minimum charging rate for the vehicle in kw. Used to calculate charging time.*/
  minChargingRate?: number;
  /** Base legs used to calculate this comparisons energy usage. */
  baseLegs: TripBaseLeg[];

  // ----------------------------------------------------------------------- //
  // ---------------------------- Constructor ------------------------------ //
  // ----------------------------------------------------------------------- //

  /**
   * Constructs a new instance of the TripComparison class.
   *
   * @param {TripComparisonOptions} options - The options for creating the instance.
   * @throws {Error} If the vehicle does not have an EV model provided.
   * @throws {Error} If the chargeUsableInRange is not between 0 and 1.
   */
  constructor(options: TripComparisonOptions) {
    // check vehicle has an EV model to compare.
    if (!options.vehicle.evModel)
      throw new Error("Vehicle has no EV model provided");
    // check that chargeUsableInRange is between 0 and 1
    if (
      (options.chargeUsableInRange && options.chargeUsableInRange < 0) ||
      (options.chargeUsableInRange && options.chargeUsableInRange > 1)
    )
      throw new Error("Invalid chargeUsableInRange provided");

    // set class state
    this.localId = generateUniqueLocalID(
      TripComparison.usedIds,
      "trip-comparison"
    );
    this.energyData = options.energyData;
    this.vehicle = options.vehicle;
    this.baseLegs = options.baseLegs;
    if (options.chargeUsableInRange)
      this.chargeUsableInRange = options.chargeUsableInRange;

    // add to global class state
    if (!TripComparison.usedIds.includes(this.localId)) {
      TripComparison.usedIds.push(this.localId);
    }
  }

  // ----------------------------------------------------------------------- //
  // ------------------------------- Getters ------------------------------- //
  // ----------------------------------------------------------------------- //

  /**
   * Returns the total driving distance in meters.
   *
   * @return {number} The driving distance in meters.
   */
  public get drivingDistance(): number {
    return this.energyData.reduce((a, b) => a + b.Distance, 0);
  }

  /**
   * Returns the driving time in number of seconds.
   *
   * @return {number} The driving time in seconds.
   */
  public get drivingTime(): number {
    return this.energyData.reduce((a, b) => a + b.Time, 0);
  }

  public get estimatedChargingTime(): TripComparisonEstimatedChargingTime {
    const ac_charging_rate =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.minChargingRate || this.vehicle.evModel!.maxElectricPowerAC;

    const dc_charging_rate = // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.maxChargingRate || this.vehicle.evModel!.maxElectricPowerDC;

    return {
      ac_charging_rate,
      dc_charging_rate,
      ac_charging_time: (this.totalEnergy / ac_charging_rate) * 3600,
      dc_charging_time: (this.totalEnergy / dc_charging_rate) * 3600,
    };
  }

  /**
   * Returns the total energy in needed to be consumed during the trip in kWh.
   *
   * @return {number} The total energy in kWh.
   */
  public get totalEnergy(): number {
    return this.energyData.reduce((a, b) => a + b.Energy, 0);
  }

  /**
   * Calculates the total charge used during the trip.
   *
   * note: this can be more than 100% charge implying that the trip can not be
   * done on a single charge.
   *
   * @return {number} The total charge.
   */
  public get totalChargeUsed(): number {
    return this.totalEnergy / this.vehicle.totalBatteryKWh();
  }

  /**
   * Calculates the number of charging stops needed based on the total charge
   * used and the charge usable in range.
   *
   * @return {number} The number of charging stops needed.
   */
  public get numberOfChargingStopsNeeded(): number {
    return this.totalChargeUsed / this.chargeUsableInRange;
  }

  /**
   * Calculates the cost of home charging based on the private charging cost
   * and the total energy used.
   *
   * @param {number} privateChargingCost The cost of private charging per kWh.
   * @return {number} The cost of home charging.
   */
  public homeChargingCost(privateChargingCost: number): number {
    return privateChargingCost * this.totalEnergy;
  }

  /**
   * Calculates the cost of fast charging based on the public charging cost and
   * the total energy used.
   *
   * @param {Object} options - An object containing the following properties:
   *   @param {number} publicChargingCost - The cost of public charging per kWh.
   *   @param {number} publicChargingCostPerMin - The cost of public charging per minute.
   * @return {number} The cost of fast charging.
   */
  public fastChargingCost({
    publicChargingCost,
    publicChargingCostPerMin,
  }: {
    publicChargingCost: number;
    publicChargingCostPerMin: number;
  }): number {
    // Calculate the energy cost
    const energyCost = publicChargingCost * this.totalEnergy;

    // Calculate the cost for charging time
    const minsCharging = this.estimatedChargingTime.dc_charging_time / 60;
    const costForTime = minsCharging * publicChargingCostPerMin;

    // Return the total cost of fast charging
    return energyCost + costForTime;
  }

  /**
   * Calculates the equivalent fuel cost based on the fuel cost per litre and
   * the kilometers per litre.
   *
   * @param {number} fuelCostPerLitre - The cost of fuel per litre.
   * @param {number} kmPerLitre - The kilometers per litre.
   * @return {number} The equivalent fuel cost.
   */
  public equivalentFuelCost({
    fuelCostPerLitre,
    kmPerLitre,
  }: {
    fuelCostPerLitre: number;
    kmPerLitre: number;
  }): number {
    // n = distance km * ev model equivalent fuel * fuelCostPerLitre
    // n = (metersDriven / metersPerLitre) * fuelCostPerLitre
    return (this.drivingDistance / (kmPerLitre * 1000)) * fuelCostPerLitre;
  }

  /**
   * Calculates the total road user charges based on the cost per 1000 kilometers
   * and the driving distance.
   *
   * @param {number} roadUserChargesCost - The cost of road user charges per 1000 kilometers.
   * @return {number} The total road user charges.
   */
  public roadUserCharges(roadUserChargesCost: number): number {
    const drivenKm = this.drivingDistance / 1000;
    const numberOf1000km = drivenKm / 1000;
    return roadUserChargesCost * numberOf1000km;
  }
}
