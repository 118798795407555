<template>
  <v-card flat>
    <DashboardBreadcrumbs
      v-if="$vuetify.breakpoint.mdAndUp"
      :items="
        showingTrip
          ? [
              { text: 'Home' },
              { text: 'Saved trips' },
              { text: 'Trip details', disabled: true },
            ]
          : [{ text: 'Home' }, { text: 'Saved trips', disabled: true }]
      "
      @navigation="breadcrumbNavigation"
      @back="breadcrumbNavigation({ text: 'Home' })"
    />
    <TripDetailsPage
      v-if="showingTrip"
      :trip="showingTrip"
      @back="showingTrip = undefined"
      @view-trip="close"
      @view-stats="close"
    />
    <v-card v-else flat class="p7-5 pa-md-5 background">
      <v-card-title> Unsaved trips </v-card-title>
      <v-card-text>
        <UnsavedTripCarousel />
      </v-card-text>
      <v-card-title> Saved trips </v-card-title>
      <FrequentTripSavings />
      <StoredTripsGroupCard
        v-for="(group, groupIndex) in savedTripsGroupedByEV"
        :key="`saved-trip-group-${groupIndex}`"
        :groupedTrips="group"
        savedStyle
        @view-trip="showTripDetails"
      />
      <v-card v-if="!savedTripsGroupedByEV.length" flat>
        <v-card-text class="pt-0">
          You currently have no saved trips.
        </v-card-text>
      </v-card>
    </v-card>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import UnsavedTripCarousel from "../trips/history/UnsavedTripCarousel.vue";
import FrequentTripSavings from "../trips/statistics/info-panel-content/FrequentTripSavings.vue";
import Trip from "@/logic/classes/trip_classes/trip";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import DashboardBreadcrumbs, {
  type BreadcrumbsItem,
} from "./DashboardBreadcrumbs.vue";
import TripDetailsPage from "./TripDetailsPage.vue";
import { mapState } from "vuex";
import { SortedTripsGroup } from "@/logic/types/sheared_local_types";
import StoredTripsGroupCard from "../trips/history/StoredTripsGroupCard.vue";
import sortTripsViaEVGroups from "@/logic/utils/sortTripsViaEVGroups";

export default Vue.extend({
  name: "SavedTripsContent",
  components: {
    UnsavedTripCarousel,
    FrequentTripSavings,
    DashboardBreadcrumbs,
    TripDetailsPage,
    StoredTripsGroupCard,
  },
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
    savedTripsGroupedByEV(): SortedTripsGroup[] {
      return sortTripsViaEVGroups(this.savedTrips);
    },
  },
  data() {
    return {
      showingTrip: undefined as Trip | undefined,
    };
  },
  methods: {
    showTripDetails(trip: Trip) {
      this.showingTrip = trip;
    },
    breadcrumbNavigation(item: BreadcrumbsItem) {
      if (item.disabled) return;
      if (item.text === "Home") {
        this.$store.commit(MutationTypes.setDashboardDialogContent, "home");
      } else if (item.text === "Saved trips") {
        this.showingTrip = undefined;
      }
    },
    close() {
      this.showingTrip = undefined;
      this.$emit("close");
    },
  },
});
</script>
