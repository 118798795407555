<template>
  <v-card flat class="background pa-5">
    <v-list three-line color="background">
      <v-list-item-group
        v-model="selection"
        multiple
        class="d-flex flex-wrap justify-start"
      >
        <v-list-item
          v-for="group in savedTripsGroupedByEV"
          :key="group.evModel"
          :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 50%' : ''"
          :value="group.evModel"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon>
              <v-icon v-if="active" color="success"> mdi-check-circle </v-icon>
              <v-icon v-else color="primary"> mdi-map-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ getModelName(group.evModel) || "Unknown" }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-row no-gutters>
                  {{ group.trips.length }} saved route{{
                    group.trips.length > 1 ? "s" : ""
                  }}
                </v-row>
                <v-row no-gutters>
                  {{ getNumberOfTrips(group).toLocaleString() }}
                  frequent trip{{ getNumberOfTrips(group) === 1 ? "" : "s" }}
                  per year
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-card-actions>
      <v-spacer></v-spacer>
      <ElevatedBtn @click="applyFilters"> Apply filter </ElevatedBtn>
      <OutlinedBtn @click="clearFilters"> Clear filter </OutlinedBtn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import { SortedTripsGroup } from "@/logic/types/sheared_local_types";
import sortTripsViaEVGroups from "@/logic/utils/sortTripsViaEVGroups";
import Vue from "vue";
import { mapState } from "vuex";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
import OutlinedBtn from "../../ui-elements/buttons/OutlinedBtn.vue";
import { calcFrequencyAdjustment } from "@/logic/utils/calcYearsSavings";

export default Vue.extend({
  name: "GroupSelectionContent",
  components: { ElevatedBtn, OutlinedBtn },
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
    savedTripsGroupedByEV(): SortedTripsGroup[] {
      return sortTripsViaEVGroups(this.savedTrips);
    },
    ...mapState({
      evModels: (state: unknown) => (state as State).evModels,
    }),
  },
  data() {
    return { selection: [] };
  },
  methods: {
    /**
     * Returns the name of the EV model given its ID, or undefined if no match
     * is found.
     * @param id The ID of the EV model to search for.
     * @returns The name of the EV model or undefined.
     */
    getModelName(id: string): string | undefined {
      return this.evModels.find((model) => model.id === id)?.name;
    },
    /**
     * Calculates the total number of trips in a given group, taking into account
     * the frequency of each trip.
     * @param group The group to calculate the total number of trips for.
     * @returns The total number of trips in the given group, rounded up to the
     * nearest integer.
     */
    getNumberOfTrips(group: SortedTripsGroup): number {
      let count = 0;
      group.trips.forEach((trip) => {
        if (trip.frequency) {
          count += calcFrequencyAdjustment(trip.frequency);
        }
      });
      return Math.round(count);
    },
    /**
     * Clears the current filter selection and resets the group selection to
     * all groups.
     */
    clearFilters() {
      this.selection = [];
      this.$store.commit(MutationTypes.setTripsGroups, undefined);
    },
    /**
     * Applies the current filter selection to the trip groups. If a group's EV
     * model ID matches one of the IDs in the selection, it is added to the
     * temporary array. The temporary array is then passed to the Vuex store
     * mutation to update the trip groups. Finally, the group selection dialog
     * is closed.
     */
    applyFilters() {
      const tempArray: SortedTripsGroup[] = [];
      this.selection.forEach((id) => {
        const group = this.savedTripsGroupedByEV.find(
          (group) => group.evModel === id
        );
        if (group) tempArray.push(group);
      });
      this.$store.commit(MutationTypes.setTripsGroups, tempArray);
      this.emitClose();
    },
    /**
     * Emits a 'close' event to signal that the current dialog or component
     * should be closed or dismissed.
     */
    emitClose() {
      this.$emit("close");
    },
  },
});
</script>
